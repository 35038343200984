// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  lpmsUrl: 'https://lpms.dev-ss-pro.com/backend/public/',
  filemakerUrl: 'https://lpms.dev-ss-pro.com/filemaker/public_html/', 
  googleloginprovider : '401929254407-7tefcjb00pluhvg8fbv5am0qcjdpnefo.apps.googleusercontent.com',
  tinymec : '6tg8l8e6btsltykml8a3qkw3lkmygtcxwr63hkm6fvins98r',
  opsCCEmail: 'bnunes@bnlawmacau.com,bnlawyers@bnlawmacau.com',
  ipCCEmail: 'bnunes@bnlawmacau.com,ip@bn-ip.com'
};

/*/

export const environment = {
  production: true,
  lpmsUrl: 'https://api.bnlawmacau.com/',
  filemakerUrl: 'https://lpms.bnlawmacau.com/filemaker/public_html/',
  googleloginprovider : '693396946582-u4pei69a8rder4oaoh86imnug42h2inl.apps.googleusercontent.com',
  tinymec : '6tg8l8e6btsltykml8a3qkw3lkmygtcxwr63hkm6fvins98r',
  opsCCEmail: 'bnunes@bnlawmacau.com,bnlawyers@bnlawmacau.com',
  ipCCEmail: 'bnunes@bnlawmacau.com,ip@bn-ip.com'
};

export const environment = {
  production: true,
  lpmsUrl: 'https://lpms.dev-ss-pro.com/backend/public/',
  filemakerUrl: 'https://lpms.dev-ss-pro.com/filemaker/public_html/', 
  googleloginprovider : '401929254407-7tefcjb00pluhvg8fbv5am0qcjdpnefo.apps.googleusercontent.com',
  tinymec : '6tg8l8e6btsltykml8a3qkw3lkmygtcxwr63hkm6fvins98r',
  opsCCEmail: 'bnunes@bnlawmacau.com,bnlawyers@bnlawmacau.com',
  ipCCEmail: 'bnunes@bnlawmacau.com,ip@bn-ip.com'
};

*/